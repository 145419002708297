body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.button-hover {
 display: flex;
 flex-direction: row;
 align-items: center;
 color: rgb(130, 127, 127);
 font-weight: 500;
}

.current-value {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  background-color: #ebecee;
  border-radius: 50%;
  margin: 3px 10px 3px 0;
  cursor: pointer;
  order: 1;
}

.values-list {
  display: flex;
  flex-direction: row;
  visibility: hidden;
  order: 2;
  opacity: 0;
  transition: all 0.3s;
  background-color: #fff;
  border-radius: 40px;
  border: 2px solid rgba(57, 74, 79, 0.5);
}

.values-list-item {
  padding: 10px 0 10px 10px;
  cursor: pointer;
  transition: all 0.3s;
}

.values-list-item:hover {
  color: rgb(53, 52, 52);
}

.values-list-item:last-child {
  padding: 10px;
}

.button-hover:hover .values-list {
  opacity: 1;
  visibility: visible;
}
